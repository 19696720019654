<template lang="pug">
  v-app
    v-card(
      class="mom-card"
      style="border-radius: 20px"
    )
      v-btn(class="m-auto" text tile color="primary" block depressed to="/login") {{ $t("auth.gologin") }}
      v-card-title
        h3(style="color: black")
          b {{ $t("auth.information") }}
        v-spacer
        v-icon(
          @click="$router.go(-1)"
          color="primary"
          v-bind="size"
        ) mdi-close-box
      v-card-text
        v-form(
          v-model="valid"
          ref="register"
          lazy-validation
        )
          label(for="name") {{ $t("auth.name") }}
          v-text-field(
            name="name"
            outlined
            v-model.trim="customerName" 
            :rules="nameRules"
            required
          )
          label(for="class") {{ $t("auth.class") }}
          v-text-field(
            name="class"
            outlined
            v-model.trim="customerClass" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
          )
          label(for="state") {{ $t("auth.state") }}
          v-select(
            :placeholder="$t('auth.select')"
            name="state"
            :items="state_list"
            item-text="name"
            item-value="id"
            outlined
            v-model.trim="customerState" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
          )
          label(for="area") {{ $t("auth.area") }}
          v-select(
            :placeholder="$t('auth.select')"
            :disabled="!customerState"
            name="area"
            :items="area_list"
            item-text="program_district"
            item-value="program_district"
            outlined
            v-model.trim="customerArea" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
          )
          label(for="school") {{ $t("auth.school") }}
          v-select(
            :placeholder="$t('auth.select')"
            :disabled="!customerArea"
            :items="school_list"
            item-text="school"
            item-value="school"
            name="school"
            outlined
            v-model.trim="customerSchool" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
          )
          label(for="race") {{ $t("auth.race") }}
          v-select(
            :placeholder="$t('auth.select')"
            name="race"
            :items="race_list"
            outlined
            v-model="customerRace" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
          )
          label(for="email") {{ $t("auth.email") }}
          v-text-field(
            name="email"
            outlined
            v-model.trim="customerEmail" 
            :rules="emailRules"
            required
          )
          label(for="code") {{ $t("auth.code") }}
          v-text-field(
            :disabled="!customerSchool"
            name="code"
            outlined
            v-model.trim="code" 
            :rules="[v => !!v || this.$t(`auth.required`)]"
            required
            @change="verifyCode"
          )
          label(for="password") {{ $t("auth.password") }}
          v-text-field(
            type="password"
            name="password"
            outlined
            v-model.trim="password" 
            :rules="passwordRules"
            required
          )
      v-card-actions.d-flex.flex-column
        small.text-danger.m-auto.mb-4(v-if="codeValid == false") Isikan kod yang sah.
        v-btn(class="m-auto mb-4" color="primary" @click="register") 
          b {{ $t("auth.register") }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  name: "Login",
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  watch: {
    customerState(val) {
      if (val) this.getAreaList();
    },
    customerArea(val) {
      if (val) this.getSchoolList();
    }
  },
  data() {
    return {
      codeValid: null,
      valid: false,
      state_list: [],
      area_list: [],
      school_list: [],
      race_list: ["Melayu", "Cina", "India", "Lain-Lain"],
      customerName: "",
      customerClass: "",
      customerEmail: "",
      customerState: null,
      customerArea: null,
      customerSchool: null,
      customerRace: null,
      code: "",
      password: "",
      nameRules: [
        v => !!v || this.$t("auth.name_required"),
        // v => /^[a-z\s]*$/i.test(v) || this.$t("registration.NAME_ALPHABETICAL"),
        v => (v && v.length <= 100) || this.$t("auth.name_format")
      ],
      emailRules: [
        v => !!v || this.$t("auth.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("auth.email_format")
      ],
      passwordRules: [
        v => !!v || this.$t("auth.password_required"),
        v => (v && v.length >= 8) || this.$t("auth.password_format")
      ],
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  mounted() {
    this.getStateList();
  },
  methods: {
    register() {
      if (!this.$refs.register.validate()) return;

      if (!this.codeValid) return;

      let url = config.registerUrl();

      var json = {
        name: this.customerName,
        class: this.customerClass,
        state: this.state_list.find(x => x.id == this.customerState).name,
        district: this.customerArea,
        school: this.customerSchool,
        race: this.customerRace,
        email: this.customerEmail,
        code: this.code,
        password: this.password,
        isAdmin: false
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.$store.commit(
                  "customer/customerRegister",
                  response.body.data
                );
                this.$swal(
                  this.$t("success.register"),
                  this.$t("success.register"),
                  "success"
                );
                this.$router.push("/profile");
              } else {
                this.$swal(
                  this.$t("error.register"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.register"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.register"),
            response.body.error.message,
            "error"
          );
        });
    },
    reset() {
      this.$refs.register.reset();
    },
    getStateList() {
      let url = config.getStateListUrl();

      this.loading = true;

      this.$http
        .get(url)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            console.log(response);
            if (response) {
              if (!this.$helpers.handleResponse(response.body.status)) return;

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.state_list = response.body.data;
              } else {
                this.$swal(
                  this.$t("error.get_state_list"),
                  response.body.statusText,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.get_state_list"),
              response.body.statusText,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.get_state_list"),
            response.body.statusText,
            "error"
          );
        });
    },
    getAreaList() {
      let url = config.getAreaListUrl();

      this.loading = true;

      var json = {
        stateId: this.customerState
      };

      this.$http
        .post(url, json)
        .then(function(response) {
          // Success
          try {
            console.log(response);
            if (response) {
              this.loading = false;

              if (!this.$helpers.handleResponse(response.body.status)) return;

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.area_list = response.body.data;
              } else {
                this.$swal(
                  this.$t("error.get_area_list"),
                  response.body.statusText,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.get_area_list"),
              response.body.statusText,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.get_area_list"),
            response.body.statusText,
            "error"
          );
        });
    },
    getSchoolList() {
      let url = config.getSchoolListUrl();

      this.loading = true;

      var json = {
        district: this.customerArea
      };

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            console.log(response);
            if (response) {
              if (!this.$helpers.handleResponse(response.body.status)) return;

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.school_list = response.body.data;
              } else {
                this.$swal(
                  this.$t("error.get_school_list"),
                  response.body.statusText,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.get_school_list"),
              response.body.statusText,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.get_school_list"),
            response.body.statusText,
            "error"
          );
        });
    },
    verifyCode() {
      if (!this.customerSchool) return;
      let url = config.verifyCodeUrl();

      this.loading = true;

      var json = {
        district: this.customerArea,
        school: this.customerSchool,
        code: this.code
      };
      this.codeValid = false;
      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            console.log(response);
            if (response) {
              if (!this.$helpers.handleResponse(response.body.status)) return;

              this.feedback.success =
                response.body.data.verifyCode &&
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.$swal(
                  this.$t("success.verify_code"),
                  response.body.statusText,
                  "success"
                );
                this.codeValid = true;
              } else {
                this.code = "";
                this.$swal(
                  this.$t("error.verify_code"),
                  response.body.statusText,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.verify_code"),
              response.body.statusText,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.verify_code"),
            response.body.statusText,
            "error"
          );
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.mom-card
  width: 1000px
  margin: auto
  margin-top: 8vw
  padding: 1rem
  margin-bottom: 4rem

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: unset
    max-width: 600px
    margin-top: 12vw
    margin-left: 1rem
    margin-right: 1rem
</style>
